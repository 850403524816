<template>
  <div class="contact">
    <nav-bar />
    <banner :imgUrl="imgUrl" />
    <div class="container wow animate__slideInRight" data-wow-duration="1s">
      <div class="contact-content">
        <container-title
          title="为您提供一站式软件管理设计方案"
          desc="TO PROVIDE YOU WITH ONE-STOP SOFTWARE MANAGEMENT DESIGN"
        />
        <p class="hot-line">联系邮箱: <span>business@cdthjy.com</span></p>
      </div>
      <div class="form">
        <div class="input-group">
          <label for="userName">您的姓名</label>
          <input
            type="text"
            name="userName"
            id="userName"
            :class="{ input_error: userNameErr }"
            v-model="userName"
          />
          <span>*</span>
          <span v-show="userNameErr" class="span_error">请输入姓名</span>
        </div>
        <div class="input-group">
          <label for="phone">联系电话</label>
          <input
            type="text"
            name="phone"
            id="phone"
            :class="{ input_error: phoneErr }"
            v-model="phone"
          />
          <span>*</span>
          <span v-show="phoneErr" class="span_error">请输入正确的联系电话</span>
        </div>
        <div class="input-group">
          <label for="email">联系邮箱</label>
          <input
            type="text"
            name="email"
            id="email"
            :class="{ input_error: emailErr }"
            v-model="email"
          />
          <span>*</span>
          <span v-show="emailErr" class="span_error">请输入正确的联系邮箱</span>
        </div>
        <div class="input-group">
          <label for="remark">其他备注</label>
          <textarea
            name="remark"
            id="remark"
            cols="30"
            rows="5"
            :class="{ input_error: remarkErr }"
            v-model="remark"
          ></textarea>
          <span>*</span>
          <span v-show="remarkErr" class="span_error">请输入备注信息</span>
        </div>
        <button @click="handleClickSubmit">提交</button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '../components/nav'
import Footer from '../components/Footer'
import banner from '../components/banner'
import containerTitle from '../components/containerTitle'
import axios from 'axios'
import Swal from 'sweetalert2'
window.Swal = Swal
export default {
  name: 'Home',
  components: {
    NavBar,
    Footer,
    banner,
    containerTitle
  },
  data() {
    return {
      imgUrl: require('../assets/AD0I1-nNBhACGAAgmdKUyQUohKfRxwMwgA84pgQ.jpg'),
      userName: '',
      userNameErr: false,
      phone: '',
      phoneErr: false,
      email: '',
      emailErr: false,
      remark: '',
      remarkErr: false
    }
  },
  watch: {
    userName(val) {
      if (this.userNameErr) this.userNameErr = !val
    },
    phone(val) {
      if (this.phoneErr) this.phoneErr = !val
    },
    email(val) {
      if (this.emailErr) this.emailErr = !val
    },
    remark(val) {
      if (this.remarkErr) this.remarkErr = !val
    }
  },
  computed: {
    disabled() {
      return (
        this.userNameErr || this.phoneErr || this.emailErr || this.remarkErr
      )
    }
  },
  methods: {
    handleClickSubmit() {
      if (!this.userName) this.userNameErr = true
      else this.userNameErr = false

      if (!this.phone) {
        this.phoneErr = true
      } else this.phoneErr = false

      if (!this.email) this.emailErr = true
      else this.emailErr = false

      if (!this.remark) this.remarkErr = true
      else this.remarkErr = false

      var regexPhone = /^1[3456789]\d{9}$/
      if (!regexPhone.test(this.phone)) this.phoneErr = true

      var regexEmail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (!regexEmail.test(this.email)) this.emailErr = true

      if (this.userNameErr || this.phoneErr || this.emailErr || this.remarkErr)
        return false

      axios({
        method: 'get',
        url:
          (process.env.NODE_ENV === 'production'
            ? 'https://www.cdthjy.com:1443'
            : '') + '/gateway/api/thCompany/leaveMessage/202104001',
        params: {
          userName: this.userName,
          phone: this.phone,
          email: this.email,
          remark: encodeURIComponent(this.remark)
        }
      }).then(({ data }) => {
        if (data.code === '00000') {
          Swal.fire('提示', '提交成功.', 'success')

          this.userName = ''
          this.phone = ''
          this.email = ''
          this.remark = ''

          this.userNameErr = false
          this.phoneErr = false
          this.emailErr = false
          this.remarkErr = false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.contact {
  .container {
    display: flex;
    padding-bottom: 100px;
    justify-content: space-between;
    .contact-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      /deep/ .container-title {
        margin-bottom: 0;
        padding-bottom: 40px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 50px;
          border-bottom: 1px solid #000;
        }
      }
      .hot-line {
        padding-top: 50px;
        font-size: 20px;
        color: #000;
        line-height: 28px;
        span {
          font-size: 32px;
        }
      }
    }

    .form {
      margin-top: 100px;
      .input-group {
        padding: 12px 0;
        display: flex;
        align-items: center;
        position: relative;
        label {
          line-height: 36px;
          font-size: 14px;
          color: #666666;
          font-weight: 500;
          padding-right: 10px;
        }
        input,
        textarea {
          width: 335px;
          height: 34px;
          border: 1px solid #dadada;
          padding: 5px 10px;
          &.input_error {
            border-color: #f56c6c;
          }
        }
        textarea {
          height: initial;
        }
        span {
          color: red;
          display: inline-block;
          margin-left: 10px;
        }
        .span_error {
          color: #f56c6c;
          font-size: 12px;
          line-height: 1;
          padding-top: 4px;
          position: absolute;
          bottom: -5px;
          left: 58px;
        }
      }
      button {
        margin-top: 15px;
        border: 0;
        outline: 0;
        background: #8e8e8e;
        color: #fff;
        font-size: 14px;
        line-height: 44px;
        width: 180px;
        margin-left: -110px;
      }
    }
  }
}
</style>
